import styled from "styled-components";

export const Hero = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${(props) => (props.bg !== undefined ? props.bg : "")});
  background-size: cover;
  background-position: center;
  position: relative;
  padding:  0;
  min-height: ${(props) => (props.height !== undefined ? props.height : "100vh")};
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.6);
  }
`;

export const Section = styled.section`
  padding: 60px 0;
  width: 100%;
`;
