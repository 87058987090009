import { action, thunk } from "easy-peasy";
import axios from "axios";
import Cookies from "universal-cookie/es6";

const cookie = new Cookies();
const token = cookie.get("jwt");


const auth = {
  currentUser: null,
  loading: false,
  message: "",
  error: "",
  getUser: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/users/me`, { headers: { Authorization: `Bearer ${cookie.get("jwt")}` } });
      actions.setUser(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),
  updateUser: thunk(async (actions, payload) => {
    actions.init();
    try {
      const { data } = await axios.patch(`${process.env.REACT_APP_API}/users/updateMe`, payload, {
        headers: { Authorization: `Bearer ${cookie.get("jwt")}` },
      });
      actions.setUser(data.data.user);
      action.setMessage("Profile update successful");
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),
  updatePassword: thunk(async (actions, payload) => {
    actions.init();
    try {
      const { data } = await axios.patch(`${process.env.REACT_APP_API}/users/updatePasswoord`, payload, {
        headers: { Authorization: `Bearer ${cookie.get("jwt")}` },
      });
      actions.setMessage("Password updated successfully ");
      cookie.set("jwt", data.token);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),
  deactivateAccount: thunk(async (actions, payload) => {
    actions.init();
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API}/users/deactivate`, payload, {
        headers: { Authorization: `Bearer ${cookie.get("jwt")}` },
      });
      actions.setMessage(data.message);
      cookie.set("jwt", data.token);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),
  deleteAccount: thunk(async (actions, id) => {
    actions.init();
    try {
      const { data } = await axios.delete(`${process.env.REACT_APP_API}/users/delete/${id}`, {
        headers: { Authorization: `Bearer ${cookie.get("jwt")}` },
      });
      actions.setMessage("Account deleted successfully");
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),
  loginUser: thunk(async (actions, payload) => {
    actions.init();
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API}/users/login`, payload);
      setTimeout(() => {
        cookie.set("jwt", data.token);
        window.location.reload()
      }, 1999)
      setTimeout(() => {
       actions.setUser(data.data.user);
      }, 2500)
     
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),
  forgotPassword: thunk(async (actions, payload) => {
    actions.init();
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API}/users/forgotPassword`, payload);
      actions.setMessage(data.message);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),
  resetPassword: thunk(async (actions, payload, params) => {
    actions.init();
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API}/users/resetPassword/${params}`, payload);
      actions.setUser(data.data.user);
      cookie.set("jwt", data.token);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),
  logOut: thunk(async (actions) => {
    actions.init();
    
    try {
    await axios.get(`${process.env.REACT_APP_API}/users/logout`, { headers: { Authorization: `Bearer ${token}` } });
     
        cookie.remove("jwt");
        actions.setUser(null);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),
  signupUser: thunk(async (actions, payload) => {
    actions.init();
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API}/users/signup`, payload);
      // actions.setUser(data.data.user);
      actions.setMessage('User created successfully');
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),
  init: action((state) => {
    state.loading = true;
    state.message = "";
    state.error = "";
  }),

  setUser: action((state, payload) => {
    state.currentUser = payload;
    state.loading = false;
  }),
  setError: action((state, error) => {
    state.error = error.message;
    state.loading = false;
  }),
  setMessage: action((state, message) => {
    state.message = message;
    state.loading = false;
  }),
};

export default auth;
