import { action, thunk } from "easy-peasy";
import axios from "axios";
import Cookies from "universal-cookie/es6";

const cookie = new Cookies();
const token = cookie.get("jwt");

const telegrams = {
  payloads: null,
  loading: false,
  message: "",
  error: "",
  createTelegram: thunk(async (actions, payload) => {
    actions.init();
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API}/telegrams`, payload, { headers: { Authorization: `Bearer ${token}` } });
      actions.setMessage(data.message);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),
  fetchTelegrams: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/telegrams`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setPayloads(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),
  fetchTelegram: thunk(async (actions, id) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/telegrams/${id}`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setPayloads(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),
  updateTelegram: thunk(async (actions, id) => {
    actions.init();
    try {
      const { data } = await axios.put(`${process.env.REACT_APP_API}/telegrams/${id}`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setMessage(data.message);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),
  deleteTelegram: thunk(async (actions, id) => {
    actions.init();
    try {
      const { data } = await axios.delete(`${process.env.REACT_APP_API}/telegrams/${id}`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setMessage("Telegram handle deleted successfully");
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),




  init: action((state) => {
    state.loading = true;
    state.error = "";
    state.message = "";
  }),

  setPayloads: action((state, payload) => {
    state.payloads = payload;
    state.loading = false;
  }),
  setError: action((state, error) => {
    state.error = error.message;
    state.loading = false;
  }),
  setMessage: action((state, message) => {
    state.message = message;
    state.loading = false;
  }),
};

export default telegrams;
