export default {
  items: [
    {
      title: true,
      name: 'Admin Panel'
    },
    // {
    //   name: 'Dashboard',
    //   url: '/dashboard',
    //   icon: 'icon-grid'
    // },
    {
      name: 'Investors',
      url: '/investors',
      icon: 'icon-people',
      badge: {
        variant: 'info',
      },
    },
    {
      name: 'Deposits Requests',
      url: '/deposits',
      icon: 'icon-wallet',
      badge: {
        variant: 'info',
      },
    },
    {
      name: 'Withdrawal Requests',
      url: '/withdrawals',
      icon: 'icon-wallet',
      badge: {
        variant: 'info',
      },
    },
    {
      name: 'Transactions',
      url: '/transactions',
      icon: 'icon-credit-card',
      badge: {
        variant: 'info',
      },
    },
  ]
};
