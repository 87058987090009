import React from 'react';
import Input from '../../../components/Input/Input';
import Title from '../../../components/Typography/Title';

export default function InputCode({ label, ...props }) {
  return (
    <div>
      <Title size={20} htmlFor={props.id}>{label}</Title>
      <br />
      <Input {...props} />
      <br />
      <br />
    </div>
  );
}
