import React from 'react';
import Loadable from 'react-loadable'
import loader from '../assets/img/loading.svg'
import WriterLayout from '../containers/WriterLayout';


function Loading() {
  return <div style={{display: 'flex', justifyContent: 'center', height: '100vh'}}><img src={loader} style={{ alignSelf: 'center', height: '60px', width: '60px'}}/></div>;
}



const Breadcrumbs = Loadable({
  loader: () => import('../views/Base/Breadcrumbs'),
  loading: Loading,
});

const Cards = Loadable({
  loader: () => import('../views/Base/Cards'),
  loading: Loading,
});

const Carousels = Loadable({
  loader: () => import('../views/Base/Carousels'),
  loading: Loading,
});

const Collapses = Loadable({
  loader: () => import('../views/Base/Collapses'),
  loading: Loading,
});

const Dropdowns = Loadable({
  loader: () => import('../views/Base/Dropdowns'),
  loading: Loading,
});

const Jumbotrons = Loadable({
  loader: () => import('../views/Base/Jumbotrons'),
  loading: Loading,
});

const ListGroups = Loadable({
  loader: () => import('../views/Base/ListGroups'),
  loading: Loading,
});

const Navbars = Loadable({
  loader: () => import('../views/Base/Navbars'),
  loading: Loading,
});

const Navs = Loadable({
  loader: () => import('../views/Base/Navs'),
  loading: Loading,
});

const Paginations = Loadable({
  loader: () => import('../views/Base/Paginations'),
  loading: Loading,
});

const Popovers = Loadable({
  loader: () => import('../views/Base/Popovers'),
  loading: Loading,
});

const ProgressBar = Loadable({
  loader: () => import('../views/Base/ProgressBar'),
  loading: Loading,
});

const Switches = Loadable({
  loader: () => import('../views/Base/Switches'),
  loading: Loading,
});

const Tabs = Loadable({
  loader: () => import('../views/Base/Tabs'),
  loading: Loading,
});

const Tooltips = Loadable({
  loader: () => import('../views/Base/Tooltips'),
  loading: Loading,
});

const BrandButtons = Loadable({
  loader: () => import('../views/Buttons/BrandButtons'),
  loading: Loading,
});

const ButtonDropdowns = Loadable({
  loader: () => import('../views/Buttons/ButtonDropdowns'),
  loading: Loading,
});

const ButtonGroups = Loadable({
  loader: () => import('../views/Buttons/ButtonGroups'),
  loading: Loading,
});

const Buttons = Loadable({
  loader: () => import('../views/Buttons/Buttons'),
  loading: Loading,
});

const LoadingButtons = Loadable({
  loader: () => import('../views/Buttons/LoadingButtons'),
  loading: Loading,
});


//Main Area
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////


const Dashboard = Loadable({
  loader: () => import('../views/Dashboard'),
  loading: Loading,
});



const Publish = Loadable({
  loader: () => import('../views/Writer/Publish'),
  loading: Loading,
});

const Articles = Loadable({
  loader: () => import('../views/Writer/Articles'),
  loading: Loading,
});

const SingleArticle = Loadable({
  loader: () => import('../views/Writer/Articles/SingleArticle'),
  loading: Loading,
});

const ReviewSingleArticle = Loadable({
  loader: () => import('../views/Writer/Articles/ReviewSingleArticle'),
  loading: Loading,
});

const ReviewSingleArticlePR = Loadable({
  loader: () => import('../views/Writer/Articles/ReviewSinglePR'),
  loading: Loading,
});

const EditArticle = Loadable({
  loader: () => import('../views/Writer/Articles/EditArticle'),
  loading: Loading,
});

const DraftArticle = Loadable({
  loader: () => import('../views/Writer/Drafts/EditDraft'),
  loading: Loading,
});

const ApprovedArticles = Loadable({
  loader: () => import('../views/Writer/Articles/Subpages/ApprovedArticle'),
  loading: Loading,
});

const PendingArticles = Loadable({
  loader: () => import('../views/Writer/Articles/Subpages/PendingArticle'),
  loading: Loading,
});

const FailedArticles = Loadable({
  loader: () => import('../views/Writer/Articles/Subpages/FailedArticle'),
  loading: Loading,
});

const Drafts = Loadable({
  loader: () => import('../views/Writer/Drafts'),
  loading: Loading,
});


const ProfileAccount = Loadable({
  loader: () => import('../views/Profile'),
  loading: Loading,
});

const EditProfileAccount = Loadable({
  loader: () => import('../views/Profile/EditProfile'),
  loading: Loading,
});

const Account = Loadable({
  loader: () => import('../views/Writer/Users'),
  loading: Loading,
});


const AccountPreview = Loadable({
  loader: () => import('../views/Editor/Users/AccountPreview'),
  loading: Loading,
});



//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
////////////////PRESS RELEASE/////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////


const PublishPR = Loadable({
  loader: () => import('../views/Writer/Publish/PressRelease'),
  loading: Loading,
});

const PressReleases = Loadable({
  loader: () => import('../views/Writer/Articles/PressRelease'),
  loading: Loading,
});

const EditPressRelease = Loadable({
  loader: () => import('../views/Writer/Articles/EditArticlePR'),
  loading: Loading,
});

const DraftPressRelease = Loadable({
  loader: () => import('../views/Writer/Drafts/EditDraftPR'),
  loading: Loading,
});

const ApprovedPressReleases = Loadable({
  loader: () => import('../views/Writer/Articles/Subpages/ApprovedPR'),
  loading: Loading,
});

const PendingPressReleases = Loadable({
  loader: () => import('../views/Writer/Articles/Subpages/PendingPR'),
  loading: Loading,
});

const FailedPressReleases = Loadable({
  loader: () => import('../views/Writer/Articles/Subpages/FailedPR'),
  loading: Loading,
});

const PressReleaseDrafts = Loadable({
  loader: () => import('../views/Writer/Drafts/PressRelase'),
  loading: Loading,
});











//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////


const CoreUIIcons = Loadable({
  loader: () => import('../views/Icons/CoreUIIcons'),
  loading: Loading,
});

const Flags = Loadable({
  loader: () => import('../views/Icons/Flags'),
  loading: Loading,
});

const FontAwesome = Loadable({
  loader: () => import('../views/Icons/FontAwesome'),
  loading: Loading,
});

const SimpleLineIcons = Loadable({
  loader: () => import('../views/Icons/SimpleLineIcons'),
  loading: Loading,
});



const Colors = Loadable({
  loader: () => import('../views/Theme/Colors'),
  loading: Loading,
});

const Typography = Loadable({
  loader: () => import('../views/Theme/Typography'),
  loading: Loading,
});


// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', name: 'Writer', component: WriterLayout, exact: true },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/writer/articles/all', name: 'All Articles', component: Articles },
  { path: '/writer/article/view/:id', name: 'View Articles', component: SingleArticle },
  { path: '/writer/article/review/:id', name: 'Review Articles', component: ReviewSingleArticle },
  { path: '/writer/press-release/review/:id', name: 'Review Articles', component: ReviewSingleArticlePR },
  { path: '/writer/article/edit/:id', name: 'Edit Articles', component: EditArticle },
  { path: '/writer/draft/edit/:id', name: 'Edit Drafts', component: DraftArticle },
  { path: '/writer/articles/create', name: 'Create Article', component: Publish },
  { path: '/writer/articles/approved', name: 'Approved Articles', component: ApprovedArticles },
  { path: '/writer/articles/pending', name: 'Pending Articles', component: PendingArticles },
  { path: '/writer/articles/failed', name: 'Failed Articles', component: FailedArticles },
  { path: '/writer/drafts', name: 'All Drafts', component: Drafts },

  { path: '/writer/account/profile', name: 'Edit Account', component: ProfileAccount },
  { path: '/account/edit', name: 'Edit Account', component: EditProfileAccount },

  { path: '/writer/accounts', name: 'All Accounts', component: Account },
  { path: '/writer/view/account/:id', name: 'View Accounts', component: AccountPreview },
  



//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////PRESS RELEASE///////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////

{ path: '/writer/press-release/create', name: 'Create Press Release', component: PublishPR },
{ path: '/writer/press-release/all', name: 'All Articles', component: PressReleases },
{ path: '/writer/press-release/approved', name: 'Approved Articles', component: ApprovedPressReleases },
{ path: '/writer/press-release/pending', name: 'Pending Articles', component: PendingPressReleases },
{ path: '/writer/press-release/failed', name: 'Failed Articles', component: FailedPressReleases },
{ path: '/writer/press-release/drafts', name: 'All Drafts', component: PressReleaseDrafts },
{ path: '/writer/press-release/edit/:id', name: 'Edit Articles', component: EditPressRelease },
{ path: '/writer/draft/edit/press-release/:id', name: 'Edit Drafts', component: DraftPressRelease },








//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////

  { path: '/theme', name: 'Theme', component: Colors, exact: true },
  { path: '/theme/colors', name: 'Colors', component: Colors },
  { path: '/theme/typography', name: 'Typography', component: Typography },
  { path: '/base', name: 'Base', component: Cards, exact: true },
  { path: '/base/breadcrumbs', name: 'Breadcrumbs', component: Breadcrumbs },
  { path: '/base/cards', name: 'Cards', component: Cards },
  { path: '/base/carousels', name: 'Carousel', component: Carousels },
  { path: '/base/collapses', name: 'Collapse', component: Collapses },
  { path: '/base/dropdowns', name: 'Dropdowns', component: Dropdowns },
  { path: '/base/jumbotrons', name: 'Jumbotrons', component: Jumbotrons },
  { path: '/base/list-groups', name: 'List Groups', component: ListGroups },
  { path: '/base/navbars', name: 'Navbars', component: Navbars },
  { path: '/base/navs', name: 'Navs', component: Navs },
  { path: '/base/paginations', name: 'Paginations', component: Paginations },
  { path: '/base/popovers', name: 'Popovers', component: Popovers },
  { path: '/base/progress-bar', name: 'Progress Bar', component: ProgressBar },
  { path: '/base/switches', name: 'Switches', component: Switches },
  { path: '/base/tabs', name: 'Tabs', component: Tabs },
  { path: '/base/tooltips', name: 'Tooltips', component: Tooltips },
  { path: '/buttons', name: 'Buttons', component: Buttons, exact: true },
  { path: '/buttons/buttons', name: 'Buttons', component: Buttons },
  { path: '/buttons/button-dropdowns', name: 'Dropdowns', component: ButtonDropdowns },
  { path: '/buttons/button-groups', name: 'Button Groups', component: ButtonGroups },
  { path: '/buttons/brand-buttons', name: 'Brand Buttons', component: BrandButtons },
  { path: '/buttons/loading-buttons', name: 'Loading Buttons', component: LoadingButtons },
  { path: '/icons', exact: true, name: 'Icons', component: CoreUIIcons },
  { path: '/icons/coreui-icons', name: 'CoreUI Icons', component: CoreUIIcons },
  { path: '/icons/flags', name: 'Flags', component: Flags },
  { path: '/icons/font-awesome', name: 'Font Awesome', component: FontAwesome },
  { path: '/icons/simple-line-icons', name: 'Simple Line Icons', component: SimpleLineIcons },
];

export default routes;
