import React from "react";
import { Col, Row } from "react-bootstrap";
import CustomButton from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import Text from "../../components/Typography/Text";
import Title from "../../components/Typography/Title";
import { Hero, Section } from "../components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useStoreActions, useStoreState } from "easy-peasy";
import swalAlertHandler from "../../utils/alert";

export default function LoginPage() {
  const { loginUser } = useStoreActions((state) => state.auth);
  const { loading } = useStoreState((state) => state.auth);
  const { error } = useStoreState((state) => state.auth);
  const [status, setStatus] = React.useState(false);

  React.useEffect(() => {
    if (status === true && error !== "") {
      swalAlertHandler("", error);
      setStatus(false);
    }

    //eslint-disable-next-line
  }, [error]);

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("This field is required").email("Please enter a valid email address"),
    password: Yup.string().required("This field is required").min(8, "Password cannot be less than 8 characters"),
  });
  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema,
    // validateOnBlur: true,
    onSubmit: (values, { resetForm }) => {
      loginUser(values);
      setStatus(true);
      resetForm();
    },
  });

  const { errors, values, touched, handleBlur, handleChange, handleSubmit } = formik;

  return (
    <Hero height="100vh">
      <Section>
        <div className="container">
          <Title primary align="center" size={36}>
            Employee Login
          </Title>
          <Text align="center">Please enter your login credentials</Text>

          <Row className="justify-content-center _mt-md">
            <Col xs={12} md={7}>
              <form onSubmit={handleSubmit}>
                <Input
                  label="Email Address"
                  type="email"
                  icon="MdEmail"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={errors.email && touched.email ? errors.email : undefined}
                />
                <Input
                  label="Password"
                  type="password"
                  icon="MdLock"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={errors.password && touched.password ? errors.password : undefined}
                />
                <div className="mt-5">
                  <CustomButton loading={loading} type="submit" block>
                    Login
                  </CustomButton>
                </div>
              </form>
            </Col>
          </Row>
        </div>
      </Section>
    </Hero>
  );
}
