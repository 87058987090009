import React from 'react'
import { Link } from 'react-router-dom'
import { useStoreActions } from "easy-peasy";

export default function Logout({ children }) {
  const { logOut } = useStoreActions((state) => state.auth);

  return <Link style={{width: '100%'}}  to='#'  onClick={()=> logOut()}>{ children }</Link>
  
}
