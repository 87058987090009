export default {
  items: [
    {
      title: true,
      name: ''
    },
    {
      title: true,
      name: 'Writer Panel'
    },
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-grid'
    },
    {
      name: 'Create Article',
      icon: 'icon-docs',
      children: [
        {
          name: 'News',
          url: '/writer/articles/create',
        },
        {
          name: 'Press Release',
          url: '/writer/press-release/create',
        },
      ],
    },
    {
      title: true,
      name: ''
    },
    {
      title: true,
      name: 'Articles'
    },
    {
      name: 'Drafts',
      icon: 'icon-target',
      children: [
        {
          name: 'News',
          url: '/writer/drafts',
        },
        {
          name: 'Press Release',
          url: '/writer/press-release/drafts',
        },
      ],
    },
    
    {
      name: 'Articles',
      icon: 'icon-folder',
      children: [
        {
          name: 'News',
          url: '/writer/articles/all',
        },
        {
          name: 'Press Release',
          url: '/writer/press-release/all',
        },
      ],
    },
    {
      name: 'Approved',
      icon: 'icon-action-undo',
      children: [
        {
          name: 'News',
          url: '/writer/articles/approved',
        },
        {
          name: 'Press Release',
          url: '/writer/press-release/approved',
        },
      ],
    },
    {
      name: 'Pending',
      icon: 'icon-action-redo',
      children: [
        {
          name: 'News',
          url: '/writer/articles/pending',
        },
        {
          name: 'Press Release',
          url: '/writer/press-release/pending',
        },
      ],
    },
    {
      name: 'Needs Revision',
      icon: 'icon-shuffle',
      children: [
        {
          name: 'News',
          url: '/writer/articles/failed'
        },
        {
          name: 'Press Release',
          url: '/writer/press-release/failed'
        },
      ],
    },
    {
      title: true,
      name: `Writers`
    },
    {
      name: 'Users',
      url: '/writer/accounts',
      icon: 'icon-people',
    },
  ]
};
