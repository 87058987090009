import { action, thunk } from "easy-peasy";
import axios from "axios";
import Cookies from "universal-cookie/es6";

const cookie = new Cookies();
const token = cookie.get("jwt");

const editor = {
  data: null,
  single: null,
  loading: false,
  message: "",
  error: "",
  editorArticle: thunk(async (actions, payload) => {
    actions.init();
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API}/articles/editor`, payload, { headers: { Authorization: `Bearer ${token}` } });
      actions.setMessage(data.message);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  editorDrafts: thunk(async (actions, payload) => {
    actions.init();
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API}/articles/editor/drafts`, payload, { headers: { Authorization: `Bearer ${token}` } });
      actions.setMessage(data.message);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),
  

  fetchArticlesEditor: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/editor/all`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchEditorApprovedArticles: thunk(async (actions, id) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/editor/approved/${id}`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchEditorDeclinedArticles: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/editor/declined`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchEditorPendingArticles: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/editor/all/pending`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  editorApproveArticle: thunk(async (actions, id, payload) => {
    actions.init();
    try {
      // const { data } = await axios.put(`${process.env.REACT_APP_API}/articles/editor/approve/${id}`, payload, { headers: { Authorization: `Bearer ${token}` } });
      actions.setMessage(data.message);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  editorChoiceArticle: thunk(async (actions, id) => {
    actions.init();
    try {
      const { data } = await axios.put(`${process.env.REACT_APP_API}/articles/editor/choice/${id}`, {}, { headers: { Authorization: `Bearer ${token}` } });
      actions.setMessage(data.message);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  editorNotChoiceArticle: thunk(async (actions, id) => {
    actions.init();
    try {
      const { data } = await axios.put(`${process.env.REACT_APP_API}/articles/editor/not-choice/${id}`, {}, { headers: { Authorization: `Bearer ${token}` } });
      actions.setMessage(data.message);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  editorDeclinArticle: thunk(async (actions, id, payload) => {
    actions.init();
    try {
      const { data } = await axios.put(`${process.env.REACT_APP_API}/articles/editor/decline/${id}`, {}, { headers: { Authorization: `Bearer ${token}` } });
      actions.setMessage(data.message);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchDraftsEditor: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/editor/drafts`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchDraftEditor: thunk(async (actions, id) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/editor/drafts/${id}`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setSingle(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchEditorArticles: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/editor/me`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchEditorArticle: thunk(async (actions, id) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/editor/${id}`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setSingle(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  deleteArticle: thunk(async (actions, id) => {
    actions.init();
    try {
      const { data } = await axios.delete(`${process.env.REACT_APP_API}/articles/editor/delete/${id}`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setMessage('Draft deleted successfully');
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  deleteEditorArticle: thunk(async (actions, id) => {
    actions.init();
    try {
      const { data } = await axios.delete(`${process.env.REACT_APP_API}/articles/editor/${id}`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setMessage(data.message);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),










































  editorArticlePR: thunk(async (actions, payload) => {
    actions.init();
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API}/articles/editor/pr`, payload, { headers: { Authorization: `Bearer ${token}` } });
      actions.setMessage(data.message);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  editorDraftsPR: thunk(async (actions, payload) => {
    actions.init();
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API}/articles/editor/pr/drafts`, payload, { headers: { Authorization: `Bearer ${token}` } });
      actions.setMessage(data.message);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),
  

  fetchArticlesEditorPR: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/editor/pr/all`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchEditorApprovedArticlesPR: thunk(async (actions, id) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/editor/pr/approved/${id}`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchEditorDeclinedArticlesPR: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/editor/pr/declined`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchEditorPendingArticlesPR: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/editor/pr/all/pending`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),


  fetchDraftsEditorPR: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/editor/pr/drafts`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  getDraftEditorPR: thunk(async (actions, id) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/editor/pr/drafts/${id}`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setSingle(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchEditorArticlesPR: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/editor/pr/me`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

 
  init: action((state) => {
    state.single = null;
    state.loading = true;
    state.message = "";
    state.error = "";
  }),

  setData: action((state, payload) => {
    state.data = payload;
    state.loading = false;
  }),
  setSingle: action((state, payload) => {
    state.single = payload;
    state.loading = false;
  }),
  setError: action((state, error) => {
    state.error = error.message;
    state.loading = false;
  }),
  setMessage: action((state, message) => {
    state.message = message;
    state.loading = false;
  }),
};

export default editor;
