import Swal from "sweetalert2";

export const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

const swalAlertHandler = (message, error) => {
  if (message !== "") {
    Toast.fire({
      icon: "success",
      title: message,
    });
  } else if (error !== "") {
    Toast.fire({
      icon: "error",
      title: error,
    });
  } else {
    return null;
  }
};

export const ToastAlert = (type, message) => {
  Toast.fire({
    icon: type,
    title: message,
  });
};

export default swalAlertHandler;
