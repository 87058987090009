export default {
  items: [
    {
      title: true,
      name: ''
    },
    {
      title: true,
      name: 'Editor Panel'
    },
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-grid'
    },
    {
      name: 'Create Article',
      icon: 'icon-docs',
      children: [
        {
          name: 'News',
          url: '/editor/articles/create',
        },
        {
          name: 'Press Release',
          url: '/editor/press-release/create',
        },
      ],
    },
    {
      name: 'Drafts',
      icon: 'icon-target',
      children: [
        {
          name: 'News',
          url: '/editor/drafts',
        },
        {
          name: 'Press Release',
          url: '/editor/press-release/drafts',
        },
      ],
    },
    {
      name: 'My Articles',
      icon: 'icon-folder',
      children: [
        {
          name: 'News',
          url: '/editor/articles/me',
        },
        {
          name: 'Press Release',
          url: '/editor/press-release/me',
        },
      ],
    },
    {
      title: true,
      name: ''
    },
    {
      title: true,
      name: 'Review'
    },
    {
      name: 'Editors Choice',
      url: '/editor/articles/editor-choice',
      icon: 'icon-like',
    },
    {
      name: 'All Articles',
      icon: 'icon-folder-alt',
      children: [
        {
          name: 'News',
          url: '/editor/articles/all',
        },
        {
          name: 'Press Release',
          url: '/editor/press-release/all',
        },
      ],
    },
    {
      name: 'Approved',
      icon: 'icon-action-undo',
      children: [
        {
          name: 'News',
          url: '/editor/articles/approved',
        },
        {
          name: 'Press Release',
          url: '/editor/press-release/approved',
        },
      ],
    },
    {
      name: 'Pending',
      icon: 'icon-action-redo',
      children: [
        {
          name: 'News',
          url: '/editor/articles/pending',
        },
        {
          name: 'Press Release',
          url: '/editor/press-release/pending',
        },
      ],
    },   
    {
      title: true,
      name: ''
    },
    {
      title: true,
      name: `Editors`
    },
    {
      name: 'Users',
      url: '/editor/accounts',
      icon: 'icon-people',
    },
  ]
};
