import auth from "./state/auth";
import editor from "./state/editor";
import writer from "./state/writer";
import admin from "./state/admin";
import article from "./state/articles";
import tag from "./state/tag";
import newsletter from "./state/newsletter";
import announcement from "./state/announcement";
import telegram from "./state/telegram";
import glossary from "./state/glossary";
import tfa from "./state/2fa";
// import contact from "./state/contact";

const model = { auth, admin, editor, writer, article, tag, announcement, telegram, newsletter, glossary, tfa };

export default model;
