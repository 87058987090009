import React, { useCallback, useState, useEffect } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import Cookies from "universal-cookie/es6";
import styled from "styled-components";
// import { verifyOtp } from './api';
import Input from "./components/Input";
import Title from "../../components/Typography/Title";
import Text from "../../components/Typography/Text";

export default function OneTimePassword({ enabled, secret }) {
  const { verifyOtp, fetchQRCode } = useStoreActions((state) => state.tfa);
  const { payloads, result } = useStoreState((state) => state.tfa);
  const [verificationCode, setVerificationCode] = useState("");
  const [invalidCode, setInvalidCode] = useState(false);
  const { getUser } = useStoreActions((state) => state.auth);
  const [ole, setOle] = useState();


const cookie = new Cookies();
const token = cookie.get("jwt");

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API}/tfa/mfa_qr_code`,
      { headers: { Authorization: `Bearer ${token}` } }
        )
        .then(res => res.blob()).then((blob) => {
          setOle(URL.createObjectURL(blob));
      });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, [payloads !== '']);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      const payload = {
        code: verificationCode,
      };

      await verifyOtp(payload);

      if (result !== null) return window.location.replace("/");

      setInvalidCode(true);
    },
    [verificationCode]
  );


  return (
    <Wrapper>
      {!enabled && (
        <QRWrapper>
          <Title size={20} className='text-center' >Scan the QR code on your authenticator app</Title>
          {ole !== '' && (
            <QRContainer>
              <img
                src={ole}
                id='qrcode'
                loading="lazy"
                alt="qr-code"
              />
              <Text className='text-center'>
                Keep this safe and use it for manaul setup{" "}
                <span className="bg-danger">{secret}</span>
              </Text>
            </QRContainer>
          )}
        </QRWrapper>
      )}

      <Form onSubmit={handleSubmit}>
        <Input
          id="verificationCode"
          label="Verification code"
          type="number"
          autoFocus
          autoComplete="off"
          placeholder="Enter verification code"
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value)}
        />

        <VerificationButton
          className="py-1.5 rounded-sm bg-success px-4 text-white"
          type="submit"
        >
          Confirm
        </VerificationButton>

        {invalidCode && <p>Invalid verification code</p>}
      </Form>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  margin-left: 20 px;
  margin-right: 20 px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
`;

const QRWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  width: 100vw;
`;

const QRContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
`;

const VerificationButton = styled.button`
  width: 40%;
  padding: 10px 20px;
`;
