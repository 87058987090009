import { action, thunk } from "easy-peasy";
import axios from "axios";
import Cookies from "universal-cookie/es6";

const cookie = new Cookies();
const token = cookie.get("jwt");

const admin = {
  data: null,
  single: null,
  user: null,
  inactive: null,
  loading: false,
  message: "",
  error: "",


  adminArticle: thunk(async (actions, payload) => {
    actions.init();
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API}/articles/admin`, payload, { headers: { Authorization: `Bearer ${token}` } });
      actions.setMessage(data.message);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  adminArticleForward: thunk(async (actions, payload) => {
    actions.init();
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API}/articles/admin/forward`, payload, { headers: { Authorization: `Bearer ${token}` } });
      actions.setMessage(data.message);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  adminArticleForwardPR: thunk(async (actions, payload) => {
    actions.init();
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API}/articles/admin/pr/forward`, payload, { headers: { Authorization: `Bearer ${token}` } });
      actions.setMessage(data.message);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  adminDrafts: thunk(async (actions, payload) => {
    actions.init();
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API}/articles/admin/drafts`, payload, { headers: { Authorization: `Bearer ${token}` } });
      actions.setMessage(data.message);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),
  

  fetchAdminApprovedArticles: thunk(async (actions, id) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/admin/approved/${id}`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchAdminDeclinedArticles: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/admin/declined`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchAdminPendingArticles: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/admin/all/pending`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  adminApproveArticle: thunk(async (actions, id, payload) => {
    actions.init();
    try {
      // const { data } = await axios.put(`${process.env.REACT_APP_API}/articles/admin/approve/${id}`, payload, { headers: { Authorization: `Bearer ${token}` } });
      actions.setMessage(data.message);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  adminChoiceArticle: thunk(async (actions, id) => {
    actions.init();
    try {
      const { data } = await axios.put(`${process.env.REACT_APP_API}/articles/admin/choice/${id}`, {}, { headers: { Authorization: `Bearer ${token}` } });
      actions.setMessage(data.message);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  adminNotChoiceArticle: thunk(async (actions, id) => {
    actions.init();
    try {
      const { data } = await axios.put(`${process.env.REACT_APP_API}/articles/admin/not-choice/${id}`, {}, { headers: { Authorization: `Bearer ${token}` } });
      actions.setMessage(data.message);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  adminDeclinArticle: thunk(async (actions, id) => {
    actions.init();
    try {
      const { data } = await axios.put(`${process.env.REACT_APP_API}/articles/admin/decline/${id}`, {}, { headers: { Authorization: `Bearer ${token}` } });
      actions.setMessage(data.message);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchDraftsAdmin: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/admin/drafts`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchDraftAdmin: thunk(async (actions, id) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/admin/drafts/${id}`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchAdminFaileds: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/admin/status/failed`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchAdminFailedPR: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/admin/pr/failed`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchAdminArticles: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/admin/me`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),
  deleteAdminArticle: thunk(async (actions, id) => {
    actions.init();
    try {
      const { data } = await axios.delete(`${process.env.REACT_APP_API}/articles/admin/${id}`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setMessage('Deleted Successfully');
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),


  fetchArticlesAdmin: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/admin/all`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchAdminApprovedArticles: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/admin/all/approved`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchAdminFailedArticles: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/admin/all/failed`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchAdminPendingArticles: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/admin/all/pending`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchDeletedArticles: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/admin/all/deleted`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),
  
  fetchAdminArticle: thunk(async (actions, id) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/admin/${id}`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setSingle(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),












  adminArticleGuide: thunk(async (actions, payload) => {
    actions.init();
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API}/articles/admin/guide`, payload, { headers: { Authorization: `Bearer ${token}` } });
      actions.setMessage(data.message);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  adminDraftsGuide: thunk(async (actions, payload) => {
    actions.init();
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API}/articles/admin/guide/drafts`, payload, { headers: { Authorization: `Bearer ${token}` } });
      actions.setMessage(data.message);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),
  

  fetchArticlesAdminGuide: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/admin/guide/all`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchAdminApprovedArticlesGuide: thunk(async (actions, id) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/admin/guide/approved/${id}`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),


  fetchDraftsAdminGuide: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/admin/guide/drafts`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchDraftAdminGuide: thunk(async (actions, id) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/admin/guide/drafts/${id}`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setSingle(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchAdminArticlesGuide: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/admin/guide/me`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),









  deleteAdminArticle: thunk(async (actions, id) => {
    actions.init();
    try {
      const { data } = await axios.delete(`${process.env.REACT_APP_API}/articles/admin/${id}`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setMessage(data.message);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  getAccount: thunk(async (actions, id) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/users/account/${id}`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setUser(data.data.data);
      // console.log(data.data.data.type.map(item => console.log(item._id.type + 'second') + 'first'))
      // console.log(data.data.data.type.map(item => console.log(item)))
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  getAccounts: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/users`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
      // console.log(data.data.data.type.map(item => console.log(item._id.type + 'second') + 'first'))
      // console.log(data.data.data.type.map(item => console.log(item)))
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  getInactiveAccounts: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/users/inactive`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setInactive(data.data.data);
      // console.log(data.data.data.type.map(item => console.log(item._id.type + 'second') + 'first'))
      // console.log(data.data.data.type.map(item => console.log(item)))
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),
 
  init: action((state) => {
    state.single = null;
    state.loading = true;
    state.message = "";
    state.error = "";
  }),

  setData: action((state, payload) => {
    state.data = payload;
    state.loading = false;
  }),
  setSingle: action((state, payload) => {
    state.single = payload;
    state.loading = false;
  }),
  setUser: action((state, payload) => {
    state.user = payload;
    state.loading = false;
  }),
  setInactive: action((state, payload) => {
    state.inactive = payload;
    state.loading = false;
  }),
  setError: action((state, error) => {
    state.error = error.message;
    state.loading = false;
  }),
  setMessage: action((state, message) => {
    state.message = message;
    state.loading = false;
  }),
};

export default admin;
