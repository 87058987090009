import { action, thunk } from "easy-peasy";
import axios from "axios";
import Cookies from "universal-cookie/es6";

const cookie = new Cookies();
const token = cookie.get("jwt");

const articles = {
  data: null,
  loading: false,
  error: "",
  fetchArticles: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),
   editorsChoice: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/editor-choice`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),
  editorsChoicePR: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/editor-choice/pr`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),
  fetchArticle: thunk(async (actions, id) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles${id}`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),




  init: action((state) => {
    state.loading = true;
    state.error = "";
  }),

  setData: action((state, payload) => {
    state.data = payload;
    state.loading = false;
  }),
  setError: action((state, error) => {
    state.error = error.message;
    state.loading = false;
  }),
};

export default articles;
