import React from 'react';
import Loadable from 'react-loadable'
import loader from '../assets/img/loading.svg'
import EditorLayout from '../containers/EditorLayout';


function Loading() {
  return <div style={{display: 'flex', justifyContent: 'center', height: '100vh'}}><img src={loader} style={{ alignSelf: 'center', height: '60px', width: '60px'}}/></div>;
}



const Breadcrumbs = Loadable({
  loader: () => import('../views/Base/Breadcrumbs/Breadcrumbs'),
  loading: Loading,
});

const Cards = Loadable({
  loader: () => import('../views/Base/Cards/Cards'),
  loading: Loading,
});

const Carousels = Loadable({
  loader: () => import('../views/Base/Carousels/Carousels'),
  loading: Loading,
});

const Collapses = Loadable({
  loader: () => import('../views/Base/Collapses/Collapses'),
  loading: Loading,
});

const Dropdowns = Loadable({
  loader: () => import('../views/Base/Dropdowns/Dropdowns'),
  loading: Loading,
});

const Jumbotrons = Loadable({
  loader: () => import('../views/Base/Jumbotrons/Jumbotrons'),
  loading: Loading,
});

const ListGroups = Loadable({
  loader: () => import('../views/Base/ListGroups/ListGroups'),
  loading: Loading,
});

const Navbars = Loadable({
  loader: () => import('../views/Base/Navbars/Navbars'),
  loading: Loading,
});

const Navs = Loadable({
  loader: () => import('../views/Base/Navs/Navs'),
  loading: Loading,
});

const Paginations = Loadable({
  loader: () => import('../views/Base/Paginations/Pagnations'),
  loading: Loading,
});

const Popovers = Loadable({
  loader: () => import('../views/Base/Popovers/Popovers'),
  loading: Loading,
});

const ProgressBar = Loadable({
  loader: () => import('../views/Base/ProgressBar/ProgressBar'),
  loading: Loading,
});

const Switches = Loadable({
  loader: () => import('../views/Base/Switches/Switches'),
  loading: Loading,
});

const Tabs = Loadable({
  loader: () => import('../views/Base/Tabs/Tabs'),
  loading: Loading,
});

const Tooltips = Loadable({
  loader: () => import('../views/Base/Tooltips/Tooltips'),
  loading: Loading,
});

const BrandButtons = Loadable({
  loader: () => import('../views/Buttons/BrandButtons/BrandButtons'),
  loading: Loading,
});

const ButtonDropdowns = Loadable({
  loader: () => import('../views/Buttons/ButtonDropdowns/ButtonDropdowns'),
  loading: Loading,
});

const ButtonGroups = Loadable({
  loader: () => import('../views/Buttons/ButtonGroups/ButtonGroups'),
  loading: Loading,
});

const Buttons = Loadable({
  loader: () => import('../views/Buttons/Buttons/Buttons'),
  loading: Loading,
});

const LoadingButtons = Loadable({
  loader: () => import('../views/Buttons/LoadingButtons/LoadingButtons'),
  loading: Loading,
});


//Main Area
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////


const Dashboard = Loadable({
  loader: () => import('../views/Dashboard/Dashboard'),
  loading: Loading,
});



const Publish = Loadable({
  loader: () => import('../views/Editor/Publish'),
  loading: Loading,
});

const Articles = Loadable({
  loader: () => import('../views/Editor/Articles'),
  loading: Loading,
});

const EditorChoice = Loadable({
  loader: () => import('../views/Editor/Articles/EditorChoice'),
  loading: Loading,
});

const SingleArticle = Loadable({
  loader: () => import('../views/Editor/Articles/SingleArticle'),
  loading: Loading,
});

const ReviewSingleArticle = Loadable({
  loader: () => import('../views/Editor/Articles/ReviewSingleArticle'),
  loading: Loading,
});

const EditArticle = Loadable({
  loader: () => import('../views/Editor/Articles/EditArticle'),
  loading: Loading,
});

const DraftArticle = Loadable({
  loader: () => import('../views/Editor/Drafts/EditDraft'),
  loading: Loading,
});

const ApprovedArticles = Loadable({
  loader: () => import('../views/Editor/Articles/Subpages/ApprovedArticle'),
  loading: Loading,
});

const MyArticles = Loadable({
  loader: () => import('../views/Editor/Articles/Subpages/MyApprovedArticle'),
  loading: Loading,
});

const PendingArticles = Loadable({
  loader: () => import('../views/Editor/Articles/Subpages/PendingArticle'),
  loading: Loading,
});

const FailedArticles = Loadable({
  loader: () => import('../views/Editor/Articles/Subpages/FailedArticle'),
  loading: Loading,
});

const Drafts = Loadable({
  loader: () => import('../views/Editor/Drafts'),
  loading: Loading,
});



const ProfileAccount = Loadable({
  loader: () => import('../views/Profile'),
  loading: Loading,
});

const EditProfileAccount = Loadable({
  loader: () => import('../views/Profile/EditProfile'),
  loading: Loading,
});

const Account = Loadable({
  loader: () => import('../views/Editor/Users'),
  loading: Loading,
});


const AccountPreview = Loadable({
  loader: () => import('../views/Editor/Users/AccountPreview'),
  loading: Loading,
});

//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
////////////    PRESS RELEASE       //////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////


const PublishPR = Loadable({
  loader: () => import('../views/Editor/Publish/PressRelease'),
  loading: Loading,
});

const ArticlesPR = Loadable({
  loader: () => import('../views/Editor/Articles/PressRelease'),
  loading: Loading,
});

const ReviewSinglePR = Loadable({
  loader: () => import('../views/Editor/Articles/ReviewSinglePR'),
  loading: Loading,
});

const EditArticlePR = Loadable({
  loader: () => import('../views/Editor/Articles/EditPR'),
  loading: Loading,
});

const DraftArticlePR = Loadable({
  loader: () => import('../views/Editor/Drafts/EditDraftPR'),
  loading: Loading,
});

const ApprovedArticlesPR = Loadable({
  loader: () => import('../views/Editor/Articles/Subpages/ApprovedPR'),
  loading: Loading,
});

const MyArticlesPR = Loadable({
  loader: () => import('../views/Editor/Articles/Subpages/MyApprovedPR'),
  loading: Loading,
});

const PendingArticlesPR = Loadable({
  loader: () => import('../views/Editor/Articles/Subpages/PendingPR'),
  loading: Loading,
});


const DraftsPR = Loadable({
  loader: () => import('../views/Editor/Drafts/PressRelease'),
  loading: Loading,
});





//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////


const CoreUIIcons = Loadable({
  loader: () => import('../views/Icons/CoreUIIcons/CoreUIIcons'),
  loading: Loading,
});

const Flags = Loadable({
  loader: () => import('../views/Icons/Flags/Flags'),
  loading: Loading,
});

const FontAwesome = Loadable({
  loader: () => import('../views/Icons/FontAwesome/FontAwesome'),
  loading: Loading,
});

const SimpleLineIcons = Loadable({
  loader: () => import('../views/Icons/SimpleLineIcons/SimpleLineIcons'),
  loading: Loading,
});



const Colors = Loadable({
  loader: () => import('../views/Theme/Colors/Colors'),
  loading: Loading,
});

const Typography = Loadable({
  loader: () => import('../views/Theme/Typography/Typography'),
  loading: Loading,
});


// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', name: 'Editor', component: EditorLayout, exact: true },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/editor/articles/me', name: 'All Articles', component: MyArticles },
  { path: '/editor/articles/all', name: 'All Articles', component: Articles },
  { path: '/editor/article/view/:id', name: 'View Articles', component: SingleArticle },
  { path: '/editor/article/review/:id', name: 'Review Article', component: ReviewSingleArticle },
  { path: '/editor/article/reviews', name: 'Review Article', component: ReviewSingleArticle },
  { path: '/editor/article/edit/:id', name: 'Edit Articles', component: EditArticle },
  { path: '/editor/draft/edit/:id', name: 'Edit Drafts', component: DraftArticle },
  { path: '/editor/articles/create', name: 'Create Article', component: Publish },
  { path: '/editor/articles/approved', name: 'Approved Articles', component: ApprovedArticles },
  { path: '/editor/articles/pending', name: 'Pending Articles', component: PendingArticles },
  { path: '/editor/articles/failed', name: 'Failed Articles', component: FailedArticles },
  { path: '/editor/drafts', name: 'All Drafts', component: Drafts },

  { path: '/editor/account/profile', name: 'Account', component: ProfileAccount },
  { path: '/account/edit', name: 'Edit Account', component: EditProfileAccount },

  { path: '/editor/articles/editor-choice', name: `Editor's Choice`, component: EditorChoice },

  { path: '/editor/accounts', name: 'All Accounts', component: Account },
  { path: '/editor/view/account/:id', name: 'View Accounts', component: AccountPreview },


//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
////////////  PRESS RELEASE   ////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////


{ path: '/editor/press-release/me', name: 'All Articles', component: MyArticlesPR },
{ path: '/editor/press-release/all', name: 'All Articles', component: ArticlesPR },
{ path: '/editor/press-release/review/:id', name: 'Review Article', component: ReviewSinglePR },
{ path: '/editor/press-release/edit/:id', name: 'Edit Articles', component: EditArticlePR },
{ path: '/editor/edit/draft/press-release/:id', name: 'Edit Drafts', component: DraftArticlePR },
{ path: '/editor/press-release/create', name: 'Create Article', component: PublishPR },
{ path: '/editor/press-release/approved', name: 'Approved Articles', component: ApprovedArticlesPR },
{ path: '/editor/press-release/pending', name: 'Pending Articles', component: PendingArticlesPR },
{ path: '/editor/press-release/drafts', name: 'All Drafts', component: DraftsPR },


//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
////////////  PRESS RELEASE   ////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////


  { path: '/theme', name: 'Theme', component: Colors, exact: true },
  { path: '/theme/colors', name: 'Colors', component: Colors },
  { path: '/theme/typography', name: 'Typography', component: Typography },
  { path: '/base', name: 'Base', component: Cards, exact: true },
  { path: '/base/breadcrumbs', name: 'Breadcrumbs', component: Breadcrumbs },
  { path: '/base/cards', name: 'Cards', component: Cards },
  { path: '/base/carousels', name: 'Carousel', component: Carousels },
  { path: '/base/collapses', name: 'Collapse', component: Collapses },
  { path: '/base/dropdowns', name: 'Dropdowns', component: Dropdowns },
  { path: '/base/jumbotrons', name: 'Jumbotrons', component: Jumbotrons },
  { path: '/base/list-groups', name: 'List Groups', component: ListGroups },
  { path: '/base/navbars', name: 'Navbars', component: Navbars },
  { path: '/base/navs', name: 'Navs', component: Navs },
  { path: '/base/paginations', name: 'Paginations', component: Paginations },
  { path: '/base/popovers', name: 'Popovers', component: Popovers },
  { path: '/base/progress-bar', name: 'Progress Bar', component: ProgressBar },
  { path: '/base/switches', name: 'Switches', component: Switches },
  { path: '/base/tabs', name: 'Tabs', component: Tabs },
  { path: '/base/tooltips', name: 'Tooltips', component: Tooltips },
  { path: '/buttons', name: 'Buttons', component: Buttons, exact: true },
  { path: '/buttons/buttons', name: 'Buttons', component: Buttons },
  { path: '/buttons/button-dropdowns', name: 'Dropdowns', component: ButtonDropdowns },
  { path: '/buttons/button-groups', name: 'Button Groups', component: ButtonGroups },
  { path: '/buttons/brand-buttons', name: 'Brand Buttons', component: BrandButtons },
  { path: '/buttons/loading-buttons', name: 'Loading Buttons', component: LoadingButtons },
  { path: '/icons', exact: true, name: 'Icons', component: CoreUIIcons },
  { path: '/icons/coreui-icons', name: 'CoreUI Icons', component: CoreUIIcons },
  { path: '/icons/flags', name: 'Flags', component: Flags },
  { path: '/icons/font-awesome', name: 'Font Awesome', component: FontAwesome },
  { path: '/icons/simple-line-icons', name: 'Simple Line Icons', component: SimpleLineIcons },
];

export default routes;
