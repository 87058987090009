import { action, thunk } from "easy-peasy";
import axios from "axios";
import Cookies from "universal-cookie/es6";

const cookie = new Cookies();
const token = cookie.get("jwt");

const tfa = {
  payloads: '',
  result: false,
  loading: false,
  message: "",
  error: "",
  verifyOtp: thunk(async (actions, payload) => {
    actions.init();
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API}/tfa/verify_otp`, payload, { headers: { Authorization: `Bearer ${token}` } });
      actions.setReslut(data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),
  fetchQRCode: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/tfa/mfa_qr_code`, { headers: { Authorization: `Bearer ${token}` } });
      <img src={data}/>
      actions.setPayloads(data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),


  init: action((state) => {
    state.loading = true;
    state.error = "";
    state.message = "";
  }),

  setPayloads: action((state, payload) => {
    state.payloads = payload;
    state.loading = false;
  }),
  
  setReslut: action((state, payload) => {
    state.result = payload;
    state.loading = false;
  }),
  setError: action((state, error) => {
    state.error = error.message;
    state.loading = false;
  }),
  setMessage: action((state, message) => {
    state.message = message;
    state.loading = false;
  }),
};

export default tfa;
