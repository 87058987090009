import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, StoreProvider } from "easy-peasy";
import model from "./model";
import "./assets/main.css";
import './index.css';
import App from './App';
// disable ServiceWorker
// import registerServiceWorker from './registerServiceWorker';
const store = createStore(model);

ReactDOM.render( <StoreProvider store={store}><App /></StoreProvider>, document.getElementById('root'));
// disable ServiceWorker
// registerServiceWorker();
