import { action, thunk } from "easy-peasy";
import axios from "axios";
import Cookies from "universal-cookie/es6";

const cookie = new Cookies();
const token = cookie.get("jwt");

const writer = {
  single: null,
  data: null,
  loading: false,
  message: "",
  error: "",
  writerArticle: thunk(async (actions, payload) => {
    actions.init();
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API}/articles/writer`, payload, { headers: { Authorization: `Bearer ${token}` } });
      actions.setMessage(data.message);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  writerDrafts: thunk(async (actions, payload) => {
    actions.init();
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API}/articles/writer/drafts`, payload, { headers: { Authorization: `Bearer ${token}` } });
      actions.setMessage(data.message);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),
  
  writerEditArticle: thunk(async (actions, id) => {
    actions.init();
    try {
      const { data } = await axios.patch(`${process.env.REACT_APP_API}/articles/writer/${id}`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setMessage(data.message);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  getWriterArticle: thunk(async (actions, id) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/writer/${id}`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setSingle(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  getWriterDraft: thunk(async (actions, id) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/writer/drafts/${id}`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setSingle(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchDraftsWriter: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/writer/drafts`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchWritersArticle: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/writer/all`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchWritersApproved: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/writer/approved`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchWritersPending: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/writer/pending`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchWritersFailed: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/writer/failed`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchWriterApproved: thunk(async (actions, id) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/writer/approved/${id}`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchWriterPending: thunk(async (actions, id) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/writer/pending/${id}`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchWriterFailed: thunk(async (actions, id) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/writer/failed/${id}`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  deleteArticle: thunk(async (actions, id) => {
    actions.init();
    try {
      const { data } = await axios.delete(`${process.env.REACT_APP_API}/articles/writer/delete/${id}`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setMessage('Draft deleted successfully');
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  ///////////////////////////////////////////////////

  /////ess Release


  /////////////////////////////////////////////////



  writerArticlePR: thunk(async (actions, payload) => {
    actions.init();
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API}/articles/writer/pr`, payload, { headers: { Authorization: `Bearer ${token}` } });
      actions.setMessage(data.message);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  writerDraftsPR: thunk(async (actions, payload) => {
    actions.init();
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API}/articles/writer/pr/drafts`, payload, { headers: { Authorization: `Bearer ${token}` } });
      actions.setMessage(data.message);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),
  
  writerEditArticlePR: thunk(async (actions, id) => {
    actions.init();
    try {
      const { data } = await axios.patch(`${process.env.REACT_APP_API}/articles/writer/pr/${id}`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setMessage(data.message);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  getWriterArticlePR: thunk(async (actions, id) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/writer/pr/${id}`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setSingle(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  getWriterDraftPR: thunk(async (actions, id) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/writer/pr/drafts/${id}`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setSingle(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchDraftsWriterPR: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/writer/pr/drafts`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchWritersArticlePR: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/writer/pr/all`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchWritersApprovedPR: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/writer/pr/approved`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchWritersPendingPR: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/writer/pr/pending`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchWritersFailedPR: thunk(async (actions) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/writer/pr/failed`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchWriterApprovedPR: thunk(async (actions, id) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/writer/pr/approved/${id}`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchWriterPendingPR: thunk(async (actions, id) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/writer/pr/pending/${id}`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  fetchWriterFailedPR: thunk(async (actions, id) => {
    actions.init();
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/articles/writer/pr/failed/${id}`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setData(data.data.data);
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),

  deleteArticlePR: thunk(async (actions, id) => {
    actions.init();
    try {
      const { data } = await axios.delete(`${process.env.REACT_APP_API}/articles/writer/pr/delete/${id}`, { headers: { Authorization: `Bearer ${token}` } });
      actions.setMessage('Draft deleted successfully');
    } catch (e) {
      actions.setError(e.response !== undefined ? e.response.data : "Error");
    }
  }),
  
  init: action((state) => {
    state.single = null;
    state.loading = true;
    state.message = "";
    state.error = "";
  }),

  setData: action((state, payload) => {
    state.data = payload;
    state.loading = false;
  }),
  setSingle: action((state, payload) => {
    state.single = payload;
    state.loading = false;
  }),
  setError: action((state, error) => {
    state.error = error.message;
    state.loading = false;
  }),
  setMessage: action((state, message) => {
    state.message = message;
    state.loading = false;
  }),
};

export default writer;
