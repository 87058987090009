import React, { Component } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import PropTypes from 'prop-types';

import { AppAsideToggler, AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import DefaultHeaderDropdown  from './DefaultHeaderDropdown'
import logo from '../../assets/img/brand/Cryptokes-logo.png'
import sygnet from '../../assets/img/brand/Cryptokes-logo.png'

import Cookies from "universal-cookie/es6";

import { useStoreActions, useStoreState } from "easy-peasy";

const cookie = new Cookies();

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

function DefaultHeader(props) {
  const { currentUser } = useStoreState((state) => state.auth);
  const { getUser } = useStoreActions((state) => state.auth);
    // eslint-disable-next-line
    const { children, ...attributes } = props;

    React.useEffect(() => {
      if (cookie.get("jwt") !== undefined) {
        getUser();
      }
      // eslint-disable-next-line
    }, []);

    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          full={{ src: logo, width: 40, height: 40, alt: 'CoreUI Logo' }}
          minimized={{ src: sygnet, width: 40, height: 40, alt: 'CoreUI Logo' }}
        />
        <AppSidebarToggler className="d-md-down-none" display="lg" />
        <Nav className="d-md-down-none" navbar>
          <NavItem className="px-3">
            <NavLink href="/">Dashboard</NavLink>
          </NavItem>
        </Nav>
        <Nav className="ml-auto" navbar>
          {/* <DefaultHeaderDropdown notif/>
          <DefaultHeaderDropdown tasks/>
          <DefaultHeaderDropdown mssgs/>
          <NavItem className="d-md-down-none">
            <NavLink href="#"><i className="icon-location-pin"></i></NavLink>
          </NavItem> */}
          <DefaultHeaderDropdown avatar={currentUser && currentUser.photo} accnt/>
          <p style={{margin: '5px 15px 5px 5px', color: '#fff'}}>{`${currentUser && currentUser.firstName} ${currentUser && currentUser.lastName}`}</p>
        </Nav>
          {' '}
        {/* <AppAsideToggler className="d-md-down-none" /> */}
        {/*<AppAsideToggler className="d-lg-none" mobile />*/}
      </React.Fragment>
    );
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default DefaultHeader;
