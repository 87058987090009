import React from "react";
import * as Md from "react-icons/md";
import * as Fa from "react-icons/fa";
import styled from "styled-components";
import colors from "../../assets/jss/colours";

const IconHolder = styled.div`
  color: ${(props) => (props.primary ? colors.primary : props.success ? colors.success : colors.success)};
  font-size: ${(props) => (props.size ? props.size + "px" : "21px")};
  margin-right: 10px;
  height: max-content;
`;

function Icons({ name, fa, ...rest }) {
  const IconName = fa !== undefined ? Fa[name] : Md[name];
  return (
    <IconHolder {...rest}>
      <IconName />
    </IconHolder>
  );
}

export default Icons;
