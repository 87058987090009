export default {
  items: [
    {
      title: true,
      name: 'Admin Panel'
    },
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-grid'
    },
    {
      name: 'Create Article',
      icon: 'icon-docs',
      children: [
        {
          name: 'News',
          url: '/admin/articles/create',
        },
        {
          name: 'Press Release',
          url: '/admin/press-release/create',
        },
        {
          name: 'Guides',
          url: '/admin/guides/create',
        },
      ],
    },
    {
      name: 'Tags',
      url: '/admin/tag/create',
      icon: 'icon-tag',
    },
    {
      name: 'Glossary',
      icon: 'icon-magnifier',
      children: [
        {
          name: 'Create',
          url: '/admin/glossary/create',
        },
        {
          name: 'Manage',
          url: '/admin/manage/glossary',
        },
      ],
    },
    {
      name: 'Newsletter',
      icon: 'icon-envelope-open',
      children: [
        {
          name: 'Compose',
          url: '/admin/newsletter/create',
        },
        {
          name: 'Subscribers',
          url: '/admin/newsletter/subscribers',
        },
        {
          name: 'Sent',
          url: '/admin/newsletter/sent',
        },
      ],
    },
    {
      name: 'Drafts',
      icon: 'icon-target',
      children: [
        {
          name: 'News',
          url: '/admin/drafts',
        },
        {
          name: 'Press Release',
          url: '/admin/press-release/drafts',
        },
        {
          name: 'Guide',
          url: '/admin/guide/drafts',
        },
      ],
    },
    {
      name: 'My Articles',
      icon: 'icon-folder',
      children: [
        {
          name: 'News',
          url: '/admin/articles/me',
        },
        {
          name: 'Press Release',
          url: '/admin/press-release/me',
        },
        {
          name: 'Guide',
          url: '/admin/guide/me',
        },
      ],
    },
    {
      name: 'Announcements',
      url: '/admin/announcement/create',
      icon: 'icon-bell'
    },
    {
      name: 'Telegram Handles',
      url: '/admin/telegram/create',
      icon: 'icon-paper-plane'
    },
    {
      title: true,
      name: 'Review'
    },
    {
      name: 'Editors Choice',
      icon: 'icon-like',
      children: [
        {
          name: 'News',
          url: '/admin/articles/editor-choice',
        },
        {
          name: 'Press Release',
          url: '/admin/articles/press/editor-choice',
        },
      ],
    },
    {
      name: 'All Articles',
      icon: 'icon-folder-alt',
      children: [
        {
          name: 'News',
          url: '/admin/articles/all',
        },
        {
          name: 'Press Release',
          url: '/admin/press-release/all',
        },
        {
          name: 'Guide',
          url: '/admin/guide/all',
        },
      ],
    },
    {
      name: 'Approved',
      icon: 'icon-action-undo',
      children: [
        {
          name: 'News',
          url: '/admin/articles/approved',
        },
        {
          name: 'Press Release',
          url: '/admin/press-release/approved',
        },
        {
          name: 'Guide',
          url: '/admin/guide/approved',
        },
      ],
    },
    {
      name: 'Pending',
      icon: 'icon-action-redo',
      children: [
        {
          name: 'News',
          url: '/admin/articles/pending',
        },
        {
          name: 'Press Release',
          url: '/admin/press-release/pending',
        },
      ],
    },
    {
      name: 'Needs Revision',
      icon: 'icon-shuffle',
      children: [
        {
          name: 'News',
          url: '/admin/articles/failed',
        },
        {
          name: 'Press Release',
          url: '/admin/press-release/failed',
        },
      ],
    },
    {
      name: 'Trash',
      url: '/admin/articles/trash',
      icon: 'icon-trash',
    },
    {
      title: true,
      name: 'Accounts'
    },
    {
      name: 'Create',
      url: '/admin/account/create',
      icon: 'icon-user-follow icons',
    },
    {
      name: 'Users',
      url: '/admin/accounts',
      icon: 'icon-people',
    },
    
  ]
};
