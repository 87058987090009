import React, { useEffect } from "react";
import Cookies from "universal-cookie/es6";
import { useStoreActions, useStoreState } from "easy-peasy";
import { HashRouter, Route, Switch, Router, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";

import "./App.css";
// Styles
// CoreUI Icons Set
import "@coreui/icons/css/coreui-icons.min.css";
// Import Flag Icons Set
import "flag-icon-css/css/flag-icon.min.css";
// Import Font Awesome Icons Set
import "font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
import "simple-line-icons/css/simple-line-icons.css";
// Import Main styles for this application
import "./scss/style.css";

// Containers
import { AdminLayout, WriterLayout, EditorLayout } from "./containers";
import Login from "./views/Login";
import OneTimePassword from "./views/OneTimePassword";

const hist = createBrowserHistory();
const cookie = new Cookies();


const Access = ({ getUser }) => {
  useEffect(() => {
    if (cookie.get("jwt") !== undefined) {
      getUser();
    }
    // eslint-disable-next-line
  }, []);
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/login" name="Home" component={Login} />
        <Route path="*">
          <Redirect to="/login" />
        </Route>
      </Switch>
    </HashRouter>
  );
};

const Writer = ({ getUser, requireMfa }) => {
  useEffect(() => {
    if (cookie.get("jwt") !== undefined) {
      getUser();
    }
    // eslint-disable-next-line
  }, []);

  if (requireMfa) {
    return <OneTimePassword enabled={true} />;
  }

  return (
    <HashRouter>
      <Switch>
        <Route path="/" name="Writer" component={WriterLayout} />
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </HashRouter>
  );
};

const Editor = ({ getUser, requireMfa }) => {
  useEffect(() => {
    if (cookie.get("jwt") !== undefined) {
      getUser();
    }
    // eslint-disable-next-line
  }, []);

  if (requireMfa) {
    return <OneTimePassword enabled={true}/>;
  }


  return (
    <HashRouter>
      <Switch>
        <Route path="/" name="Editor" component={EditorLayout} />
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </HashRouter>
  );
};

const Admin = ({ getUser, requireMfa }) => {
  useEffect(() => {
    if (cookie.get("jwt") !== undefined) {
      getUser();
    }
    // eslint-disable-next-line
  }, []);

  if (requireMfa) {
    return <OneTimePassword enabled={true} />;
  }

  return (
    <HashRouter>
      <Switch>
        <Route path="/" name="Home" component={AdminLayout} />
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </HashRouter>
  );
};

function App() {
  const { currentUser } = useStoreState((state) => state.auth);
  const { getUser } = useStoreActions((state) => state.auth);

  return (
    <Router history={hist}>
      {currentUser !== null && currentUser.mfaEnabled && currentUser.role === "admin" ? (
        <Admin getUser={getUser} requireMfa={!currentUser.requireMfa} />
      ) : currentUser !== null && currentUser.mfaEnabled && currentUser.role === "master" ? (
        <Admin getUser={getUser} requireMfa={!currentUser.requireMfa} />
      ) : currentUser !== null && currentUser.mfaEnabled && currentUser.role === "writer"  ? (
        <Writer getUser={getUser} requireMfa={!currentUser.requireMfa} />
      ) : currentUser !== null && currentUser.mfaEnabled && currentUser.role === "editor"  ? (
        <Editor getUser={getUser} requireMfa={!currentUser.requireMfa} />
      ) : currentUser !== null && currentUser.mfaEnabled === false  ? (
        <OneTimePassword enabled={false} secret={currentUser.mfaSecret} />
      ) : (
        <Access getUser={getUser} />
      )}
    </Router>
  );
}

export default App;
