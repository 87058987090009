import React from 'react';
import Loadable from 'react-loadable'
import loader from '../assets/img/loading.svg'
import AdminLayout from '../containers/AdminLayout';


function Loading() {
  return <div style={{display: 'flex', justifyContent: 'center', height: '100vh'}}><img src={loader} style={{ alignSelf: 'center', height: '60px', width: '60px'}}/></div>;
}



const Breadcrumbs = Loadable({
  loader: () => import('../views/Base/Breadcrumbs/Breadcrumbs'),
  loading: Loading,
});

const Cards = Loadable({
  loader: () => import('../views/Base/Cards/Cards'),
  loading: Loading,
});

const Carousels = Loadable({
  loader: () => import('../views/Base/Carousels/Carousels'),
  loading: Loading,
});

const Collapses = Loadable({
  loader: () => import('../views/Base/Collapses/Collapses'),
  loading: Loading,
});

const Dropdowns = Loadable({
  loader: () => import('../views/Base/Dropdowns/Dropdowns'),
  loading: Loading,
});

const Jumbotrons = Loadable({
  loader: () => import('../views/Base/Jumbotrons/Jumbotrons'),
  loading: Loading,
});

const ListGroups = Loadable({
  loader: () => import('../views/Base/ListGroups/ListGroups'),
  loading: Loading,
});

const Navbars = Loadable({
  loader: () => import('../views/Base/Navbars/Navbars'),
  loading: Loading,
});

const Navs = Loadable({
  loader: () => import('../views/Base/Navs/Navs'),
  loading: Loading,
});

const Paginations = Loadable({
  loader: () => import('../views/Base/Paginations/Pagnations'),
  loading: Loading,
});

const Popovers = Loadable({
  loader: () => import('../views/Base/Popovers/Popovers'),
  loading: Loading,
});

const ProgressBar = Loadable({
  loader: () => import('../views/Base/ProgressBar/ProgressBar'),
  loading: Loading,
});

const Switches = Loadable({
  loader: () => import('../views/Base/Switches/Switches'),
  loading: Loading,
});

const Tabs = Loadable({
  loader: () => import('../views/Base/Tabs/Tabs'),
  loading: Loading,
});

const Tooltips = Loadable({
  loader: () => import('../views/Base/Tooltips/Tooltips'),
  loading: Loading,
});

const BrandButtons = Loadable({
  loader: () => import('../views/Buttons/BrandButtons/BrandButtons'),
  loading: Loading,
});

const ButtonDropdowns = Loadable({
  loader: () => import('../views/Buttons/ButtonDropdowns/ButtonDropdowns'),
  loading: Loading,
});

const ButtonGroups = Loadable({
  loader: () => import('../views/Buttons/ButtonGroups/ButtonGroups'),
  loading: Loading,
});

const Buttons = Loadable({
  loader: () => import('../views/Buttons/Buttons/Buttons'),
  loading: Loading,
});

const LoadingButtons = Loadable({
  loader: () => import('../views/Buttons/LoadingButtons/LoadingButtons'),
  loading: Loading,
});


//Main Area
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////


const Dashboard = Loadable({
  loader: () => import('../views/Dashboard/Dashboard'),
  loading: Loading,
});



const Publish = Loadable({
  loader: () => import('../views/Admin/Publish'),
  loading: Loading,
});

const Articles = Loadable({
  loader: () => import('../views/Admin/Articles'),
  loading: Loading,
});

const EditorChoice = Loadable({
  loader: () => import('../views/Admin/Articles/EditorChoice'),
  loading: Loading,
});

const EditorChoicePR = Loadable({
  loader: () => import('../views/Admin/Articles/EditorChoicePR'),
  loading: Loading,
});

const TrashArticles = Loadable({
  loader: () => import('../views/Admin/Articles/Trash'),
  loading: Loading,
});

const SingleArticle = Loadable({
  loader: () => import('../views/Admin/Articles/SingleArticle'),
  loading: Loading,
});

const ReviewSingleArticle = Loadable({
  loader: () => import('../views/Admin/Articles/ReviewSingleArticle'),
  loading: Loading,
});

const EditArticle = Loadable({
  loader: () => import('../views/Admin/Articles/EditArticle'),
  loading: Loading,
});

const DraftArticle = Loadable({
  loader: () => import('../views/Admin/Drafts/EditDraft'),
  loading: Loading,
});

const ApprovedArticles = Loadable({
  loader: () => import('../views/Admin/Articles/Subpages/ApprovedArticle'),
  loading: Loading,
});

const MyArticles = Loadable({
  loader: () => import('../views/Admin/Articles/Subpages/MyApprovedArticle'),
  loading: Loading,
});

const PendingArticles = Loadable({
  loader: () => import('../views/Admin/Articles/Subpages/PendingArticle'),
  loading: Loading,
});

const FailedArticles = Loadable({
  loader: () => import('../views/Admin/Articles/Subpages/FailedArticle'),
  loading: Loading,
});

const Drafts = Loadable({
  loader: () => import('../views/Admin/Drafts'),
  loading: Loading,
});

const CreateTag = Loadable({
  loader: () => import('../views/Admin/Accounts/Create/Tag'),
  loading: Loading,
});

const CreateGlossary = Loadable({
  loader: () => import('../views/Admin/Glossary'),
  loading: Loading,
});

const ManageGlossary = Loadable({
  loader: () => import('../views/Admin/Glossary/Manage'),
  loading: Loading,
});

const EditGlossary = Loadable({
  loader: () => import('../views/Admin/Glossary/EditGlossary'),
  loading: Loading,
});

const CreateNewsletter = Loadable({
  loader: () => import('../views/Admin/Newsletter'),
  loading: Loading,
});

const NewsletterSubscribers = Loadable({
  loader: () => import('../views/Admin/Newsletter/Subscribers'),
  loading: Loading,
});

const SentNewsletter = Loadable({
  loader: () => import('../views/Admin/Newsletter/History'),
  loading: Loading,
});

const CreateAnnouncement = Loadable({
  loader: () => import('../views/Admin/Announcement'),
  loading: Loading,
});

const CreateTelegram = Loadable({
  loader: () => import('../views/Admin/Telegram'),
  loading: Loading,
});

const CreateAccount = Loadable({
  loader: () => import('../views/Admin/Accounts/Create'),
  loading: Loading,
});

const AccountPreview = Loadable({
  loader: () => import('../views/Admin/Accounts/AccountPreview'),
  loading: Loading,
});

const Account = Loadable({
  loader: () => import('../views/Admin/Accounts'),
  loading: Loading,
});

const EnableAccount = Loadable({
  loader: () => import('../views/Admin/Accounts/Subpages/EnabledAccount'),
  loading: Loading,
});

const DisableAccount = Loadable({
  loader: () => import('../views/Admin/Accounts/Subpages/DisabledAccount'),
  loading: Loading,
});

const ProfileAccount = Loadable({
  loader: () => import('../views/Profile'),
  loading: Loading,
});

const EditProfileAccount = Loadable({
  loader: () => import('../views/Profile/EditProfile'),
  loading: Loading,
});

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////


const PublishPR = Loadable({
  loader: () => import('../views/Admin/Publish/PressRelease'),
  loading: Loading,
});

const ArticlesPR = Loadable({
  loader: () => import('../views/Admin/Articles/PressRelease'),
  loading: Loading,
});

const ReviewSinglePR = Loadable({
  loader: () => import('../views/Admin/Articles/ReviewSinglePR'),
  loading: Loading,
});

const EditArticlePR = Loadable({
  loader: () => import('../views/Admin/Articles/EditPR'),
  loading: Loading,
});

const DraftArticlePR = Loadable({
  loader: () => import('../views/Admin/Drafts/EditDraftPR'),
  loading: Loading,
});

const ApprovedArticlesPR = Loadable({
  loader: () => import('../views/Admin/Articles/Subpages/ApprovedPR'),
  loading: Loading,
});

const MyArticlesPR = Loadable({
  loader: () => import('../views/Admin/Articles/Subpages/MyApprovedPR'),
  loading: Loading,
});

const PendingArticlesPR = Loadable({
  loader: () => import('../views/Admin/Articles/Subpages/PendingPR'),
  loading: Loading,
});


const DraftsPR = Loadable({
  loader: () => import('../views/Admin/Drafts/PressRelease'),
  loading: Loading,
});

const FailedPressReleases = Loadable({
  loader: () => import('../views/Admin/Articles/Subpages/FailedPR'),
  loading: Loading,
});



//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////


const PublishGuide = Loadable({
  loader: () => import('../views/Admin/Publish/Guide'),
  loading: Loading,
});

const ArticlesGuide = Loadable({
  loader: () => import('../views/Admin/Articles/Guide'),
  loading: Loading,
});

const SingleArticleGuide= Loadable({
  loader: () => import('../views/Admin/Articles/SingleArticleGuide'),
  loading: Loading,
});

const EditArticleGuide = Loadable({
  loader: () => import('../views/Admin/Articles/EditGuide'),
  loading: Loading,
});

const DraftArticleGuide = Loadable({
  loader: () => import('../views/Admin/Drafts/EditDraftGuide'),
  loading: Loading,
});

const ApprovedArticlesGuide = Loadable({
  loader: () => import('../views/Admin/Articles/Subpages/ApprovedGuide'),
  loading: Loading,
});

const MyArticlesGuide = Loadable({
  loader: () => import('../views/Admin/Articles/Subpages/MyApprovedGuide'),
  loading: Loading,
});


const DraftsGuide = Loadable({
  loader: () => import('../views/Admin/Drafts/Guide'),
  loading: Loading,
});



//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////



const CoreUIIcons = Loadable({
  loader: () => import('../views/Icons/CoreUIIcons/CoreUIIcons'),
  loading: Loading,
});

const Flags = Loadable({
  loader: () => import('../views/Icons/Flags/Flags'),
  loading: Loading,
});

const FontAwesome = Loadable({
  loader: () => import('../views/Icons/FontAwesome/FontAwesome'),
  loading: Loading,
});

const SimpleLineIcons = Loadable({
  loader: () => import('../views/Icons/SimpleLineIcons/SimpleLineIcons'),
  loading: Loading,
});



const Colors = Loadable({
  loader: () => import('../views/Theme/Colors/Colors'),
  loading: Loading,
});

const Typography = Loadable({
  loader: () => import('../views/Theme/Typography/Typography'),
  loading: Loading,
});


// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', name: 'Admin', component: AdminLayout, exact: true },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/admin/articles/me', name: 'All Articles', component: MyArticles },
  { path: '/admin/articles/all', name: 'All Articles', component: Articles },
  { path: '/admin/article/view/:id', name: 'View Articles', component: SingleArticle },
  { path: '/admin/article/review/:id', name: 'Review Article', component: ReviewSingleArticle },
  { path: '/admin/article/reviews', name: 'Review Article', component: ReviewSingleArticle },
  { path: '/admin/article/edit/:id', name: 'Edit Articles', component: EditArticle },
  { path: '/admin/draft/edit/:id', name: 'Edit Drafts', component: DraftArticle },
  { path: '/admin/articles/create', name: 'Create Article', component: Publish },
  { path: '/admin/articles/approved', name: 'Approved Articles', component: ApprovedArticles },
  { path: '/admin/articles/pending', name: 'Pending Articles', component: PendingArticles },
  { path: '/admin/articles/failed', name: 'Failed Articles', component: FailedArticles },
  { path: '/admin/press-release/failed', name: 'Failed Press Release', component: FailedPressReleases },
  { path: '/admin/drafts', name: 'All Drafts', component: Drafts },
  { path: '/admin/account/create', name: 'Create Account', component: CreateAccount },
  { path: '/admin/tag/create', name: 'Create Tag', component: CreateTag },
  { path: '/admin/glossary/create', name: 'Create Glossary', component: CreateGlossary },
  { path: '/admin/manage/glossary', name: 'Manage Glossary', component: ManageGlossary },
  { path: '/admin/glossary/edit/:id', name: 'Manage Glossary', component: EditGlossary },
  { path: '/admin/newsletter/create', name: 'Create Newsletter', component: CreateNewsletter },
  { path: '/admin/newsletter/subscribers', name: 'Subscribers', component: NewsletterSubscribers },
  { path: '/admin/newsletter/sent', name: 'Sent Newsletters', component: SentNewsletter },
  { path: '/admin/announcement/create', name: 'Create Tag', component: CreateAnnouncement },
  { path: '/admin/telegram/create', name: 'Create Tag', component: CreateTelegram },
  { path: '/admin/accounts', name: 'All Accounts', component: Account },
  { path: '/admin/view/account/:id', name: 'View Accounts', component: AccountPreview },
  { path: '/admin/account/enable', name: 'Active Accounts', component: EnableAccount },
  { path: '/admin/account/disable', name: 'Disabled Accounts', component: DisableAccount },
  { path: '/admin/account/profile', name: 'Edit Account', component: ProfileAccount },
  { path: '/account/edit', name: 'Edit Account', component: EditProfileAccount },
  { path: '/admin/articles/editor-choice', name: `Editor's Choice`, component: EditorChoice },
  { path: '/admin/articles/press/editor-choice', name: `Editor's Choice`, component: EditorChoicePR },
  { path: '/admin/articles/trash', name: `Trash`, component: TrashArticles },

 
  
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
////////////  PRESS RELEASE   ////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////


{ path: '/admin/press-release/me', name: 'All Articles', component: MyArticlesPR },
{ path: '/admin/press-release/all', name: 'All Articles', component: ArticlesPR },
{ path: '/admin/press-release/review/:id', name: 'Review Article', component: ReviewSinglePR },
{ path: '/admin/press-release/edit/:id', name: 'Edit Articles', component: EditArticlePR },
{ path: '/admin/edit/draft/press-release/:id', name: 'Edit PR Drafts', component: DraftArticlePR },
{ path: '/admin/press-release/create', name: 'Create Article', component: PublishPR },
{ path: '/admin/press-release/approved', name: 'Approved Articles', component: ApprovedArticlesPR },
{ path: '/admin/press-release/pending', name: 'Pending Articles', component: PendingArticlesPR },
{ path: '/admin/press-release/drafts', name: 'All Drafts', component: DraftsPR },


//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
////////////  GUIDE  ////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////


{ path: '/admin/guide/me', name: 'All Articles', component: MyArticlesGuide },
{ path: '/admin/guide/all', name: 'All Articles', component: ArticlesGuide },
{ path: '/admin/guide/review/:id', name: 'Review Article', component: SingleArticleGuide },
{ path: '/admin/guide/edit/:id', name: 'Edit Articles', component: EditArticleGuide },
{ path: '/admin/guide/draft/edit/:id', name: 'Edit Guide Drafts', component: DraftArticleGuide },
{ path: '/admin/guides/create', name: 'Create Guide', component: PublishGuide },
{ path: '/admin/guide/approved', name: 'Approved Articles', component: ApprovedArticlesGuide },
{ path: '/admin/guide/drafts', name: 'All Drafts', component: DraftsGuide },


//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
////////////  GUIDE  ////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////


  { path: '/theme', name: 'Theme', component: Colors, exact: true },
  { path: '/theme/colors', name: 'Colors', component: Colors },
  { path: '/theme/typography', name: 'Typography', component: Typography },
  { path: '/base', name: 'Base', component: Cards, exact: true },
  { path: '/base/breadcrumbs', name: 'Breadcrumbs', component: Breadcrumbs },
  { path: '/base/cards', name: 'Cards', component: Cards },
  { path: '/base/carousels', name: 'Carousel', component: Carousels },
  { path: '/base/collapses', name: 'Collapse', component: Collapses },
  { path: '/base/dropdowns', name: 'Dropdowns', component: Dropdowns },
  { path: '/base/jumbotrons', name: 'Jumbotrons', component: Jumbotrons },
  { path: '/base/list-groups', name: 'List Groups', component: ListGroups },
  { path: '/base/navbars', name: 'Navbars', component: Navbars },
  { path: '/base/navs', name: 'Navs', component: Navs },
  { path: '/base/paginations', name: 'Paginations', component: Paginations },
  { path: '/base/popovers', name: 'Popovers', component: Popovers },
  { path: '/base/progress-bar', name: 'Progress Bar', component: ProgressBar },
  { path: '/base/switches', name: 'Switches', component: Switches },
  { path: '/base/tabs', name: 'Tabs', component: Tabs },
  { path: '/base/tooltips', name: 'Tooltips', component: Tooltips },
  { path: '/buttons', name: 'Buttons', component: Buttons, exact: true },
  { path: '/buttons/buttons', name: 'Buttons', component: Buttons },
  { path: '/buttons/button-dropdowns', name: 'Dropdowns', component: ButtonDropdowns },
  { path: '/buttons/button-groups', name: 'Button Groups', component: ButtonGroups },
  { path: '/buttons/brand-buttons', name: 'Brand Buttons', component: BrandButtons },
  { path: '/buttons/loading-buttons', name: 'Loading Buttons', component: LoadingButtons },
  { path: '/icons', exact: true, name: 'Icons', component: CoreUIIcons },
  { path: '/icons/coreui-icons', name: 'CoreUI Icons', component: CoreUIIcons },
  { path: '/icons/flags', name: 'Flags', component: Flags },
  { path: '/icons/font-awesome', name: 'Font Awesome', component: FontAwesome },
  { path: '/icons/simple-line-icons', name: 'Simple Line Icons', component: SimpleLineIcons },
];

export default routes;
