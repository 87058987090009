import React from "react";
import styled from "styled-components";
import colors from "../../assets/jss/colours";

const TitleItem = styled.p`
  color: ${(props) => (props.primary || props.title ? colors.primary : props.success ? colors.success : props.danger ? colors.danger : colors.secondary)};
  font-size: ${(props) => (props.title ? "32px" : props.size ? props.size + "px" : "32px")};
  line-height: ${(props) => (props.size ? props.size + 12 + "px" : "44px")};
  font-weight: ${(props) => (props.weight ? props.weight : "700")};
  text-align: ${(props) => (props.align !== undefined ? props.align : "left")};
  display: ${(props) => (props.inline !== undefined ? "inline" : "block")};
  font-family: "Montserrat", sans-serif;
  margin-bottom: 0;
  z-index: 100;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    height: 4px;
    width: 80px;
    bottom: -5px;
    margin-bottom: 4px;
    background-color: ${colors.success};
    display: ${(props) => (props.line !== undefined ? "block" : "none")};
  }
`;

export default function Title({ children, ...rest }) {
  return <TitleItem {...rest}>{children}</TitleItem>;
}
