import React from 'react';
import Loadable from 'react-loadable'

import DefaultLayout from './containers/DefaultLayout';

function Loading() {
  return <div>Loading...</div>;
}



const Breadcrumbs = Loadable({
  loader: () => import('./views/Base/Breadcrumbs'),
  loading: Loading,
});

const Cards = Loadable({
  loader: () => import('./views/Base/Cards'),
  loading: Loading,
});

const Carousels = Loadable({
  loader: () => import('./views/Base/Carousels'),
  loading: Loading,
});

const Collapses = Loadable({
  loader: () => import('./views/Base/Collapses'),
  loading: Loading,
});

const Dropdowns = Loadable({
  loader: () => import('./views/Base/Dropdowns'),
  loading: Loading,
});

const Jumbotrons = Loadable({
  loader: () => import('./views/Base/Jumbotrons'),
  loading: Loading,
});

const ListGroups = Loadable({
  loader: () => import('./views/Base/ListGroups'),
  loading: Loading,
});

const Navbars = Loadable({
  loader: () => import('./views/Base/Navbars'),
  loading: Loading,
});

const Navs = Loadable({
  loader: () => import('./views/Base/Navs'),
  loading: Loading,
});

const Paginations = Loadable({
  loader: () => import('./views/Base/Paginations'),
  loading: Loading,
});

const Popovers = Loadable({
  loader: () => import('./views/Base/Popovers'),
  loading: Loading,
});

const ProgressBar = Loadable({
  loader: () => import('./views/Base/ProgressBar'),
  loading: Loading,
});

const Switches = Loadable({
  loader: () => import('./views/Base/Switches'),
  loading: Loading,
});

const Tabs = Loadable({
  loader: () => import('./views/Base/Tabs'),
  loading: Loading,
});

const Tooltips = Loadable({
  loader: () => import('./views/Base/Tooltips'),
  loading: Loading,
});

const BrandButtons = Loadable({
  loader: () => import('./views/Buttons/BrandButtons'),
  loading: Loading,
});

const ButtonDropdowns = Loadable({
  loader: () => import('./views/Buttons/ButtonDropdowns'),
  loading: Loading,
});

const ButtonGroups = Loadable({
  loader: () => import('./views/Buttons/ButtonGroups'),
  loading: Loading,
});

const Buttons = Loadable({
  loader: () => import('./views/Buttons/Buttons'),
  loading: Loading,
});

const LoadingButtons = Loadable({
  loader: () => import('./views/Buttons/LoadingButtons'),
  loading: Loading,
});


const Dashboard = Loadable({
  loader: () => import('./views/Dashboard'),
  loading: Loading,
});


// const Withdrawal = Loadable({
//   loader: () => import('./views/Withdrawals'),
//   loading: Loading,
// });

// const ApprovedWithdrawal = Loadable({
//   loader: () => import('./views/Withdrawals/Subpages/TransWithdraw'),
//   loading: Loading,
// });

// const PendingWithdrawal = Loadable({
//   loader: () => import('./views/Withdrawals/Subpages/PendingTransWithdraw'),
//   loading: Loading,
// });

// const FailedWithdrawal = Loadable({
//   loader: () => import('./views/Withdrawals/Subpages/FailedTransWithdraw'),
//   loading: Loading,
// });

// const Investor = Loadable({
//   loader: () => import('./views/Investors'),
//   loading: Loading,
// });

// const ActiveInvestor = Loadable({
//   loader: () => import('./views/Investors/Subpages/ActiveInvestors'),
//   loading: Loading,
// });

// const DeactivatedInvestor = Loadable({
//   loader: () => import('./views/Investors/Subpages/DeactivatedInvestors'),
//   loading: Loading,
// });
// const ApprovedDeposit = Loadable({
//   loader: () => import('./views/Deposit/Subpages/TransDeposit'),
//   loading: Loading,
// });

// const PendingDeposits = Loadable({
//   loader: () => import('./views/Deposit/Subpages/PendingTransDeposit'),
//   loading: Loading,
// });

// const FailedDeposits = Loadable({
//   loader: () => import('./views/Deposit/Subpages/FailedTransDeposit'),
//   loading: Loading,
// });

// const Deposit = Loadable({
//   loader: () => import('./views/Deposit'),
//   loading: Loading,
// });

// const Transaction = Loadable({
//   loader: () => import('./views/Transactions'),
//   loading: Loading,
// });

// const withdrawalHistory = Loadable({
//   loader: () => import('./views/Transactions/Subpages/TransWithdraw'),
//   loading: Loading,
// });

// const DepositHistory = Loadable({
//   loader: () => import('./views/Transactions/Subpages/TransDeposit'),
//   loading: Loading,
// });


const CoreUIIcons = Loadable({
  loader: () => import('./views/Icons/CoreUIIcons'),
  loading: Loading,
});

const Flags = Loadable({
  loader: () => import('./views/Icons/Flags'),
  loading: Loading,
});

const FontAwesome = Loadable({
  loader: () => import('./views/Icons/FontAwesome'),
  loading: Loading,
});

const SimpleLineIcons = Loadable({
  loader: () => import('./views/Icons/SimpleLineIcons'),
  loading: Loading,
});



const Colors = Loadable({
  loader: () => import('./views/Theme/Colors'),
  loading: Loading,
});

const Typography = Loadable({
  loader: () => import('./views/Theme/Typography'),
  loading: Loading,
});


// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', name: 'Home', component: DefaultLayout, exact: true },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  // { path: '/withdrawals', name: 'All Withdrawals', component: Withdrawal },
  // { path: '/withdrawal/successful', name: 'Approved Withdrawals', component: ApprovedWithdrawal },
  // { path: '/withdrawal/pending', name: 'Pending Withdrawals', component: PendingWithdrawal },
  // { path: '/withdrawal/failed', name: 'Failed Withdrawals', component: FailedWithdrawal },
  // { path: '/investors', name: 'All Investors', component: Investor },
  // { path: '/investor/active', name: 'Active Investors', component: ActiveInvestor },
  // { path: '/investor/deactivated', name: 'Deactivated Investors', component: DeactivatedInvestor },
  // { path: '/deposits', name: 'Deposits', component: Deposit },
  // { path: '/deposit/successful', name: 'Successful Deposits', component: ApprovedDeposit },
  // { path: '/deposit/pending', name: 'Pending Deposits', component: PendingDeposits },
  // { path: '/deposit/failed', name: 'Failed Deposits', component: FailedDeposits },
  // { path: '/transactions', name: 'Transactions', component: Transaction },
  // { path: '/transaction/withdrawals', name: 'Transaction Withdrawal History', component: withdrawalHistory },
  // { path: '/transaction/deposits', name: 'Transaction Deposit History', component: DepositHistory },
  { path: '/theme', name: 'Theme', component: Colors, exact: true },
  { path: '/theme/colors', name: 'Colors', component: Colors },
  { path: '/theme/typography', name: 'Typography', component: Typography },
  { path: '/base', name: 'Base', component: Cards, exact: true },
  { path: '/base/breadcrumbs', name: 'Breadcrumbs', component: Breadcrumbs },
  { path: '/base/cards', name: 'Cards', component: Cards },
  { path: '/base/carousels', name: 'Carousel', component: Carousels },
  { path: '/base/collapses', name: 'Collapse', component: Collapses },
  { path: '/base/dropdowns', name: 'Dropdowns', component: Dropdowns },
  { path: '/base/jumbotrons', name: 'Jumbotrons', component: Jumbotrons },
  { path: '/base/list-groups', name: 'List Groups', component: ListGroups },
  { path: '/base/navbars', name: 'Navbars', component: Navbars },
  { path: '/base/navs', name: 'Navs', component: Navs },
  { path: '/base/paginations', name: 'Paginations', component: Paginations },
  { path: '/base/popovers', name: 'Popovers', component: Popovers },
  { path: '/base/progress-bar', name: 'Progress Bar', component: ProgressBar },
  { path: '/base/switches', name: 'Switches', component: Switches },
  { path: '/base/tabs', name: 'Tabs', component: Tabs },
  { path: '/base/tooltips', name: 'Tooltips', component: Tooltips },
  { path: '/buttons', name: 'Buttons', component: Buttons, exact: true },
  { path: '/buttons/buttons', name: 'Buttons', component: Buttons },
  { path: '/buttons/button-dropdowns', name: 'Dropdowns', component: ButtonDropdowns },
  { path: '/buttons/button-groups', name: 'Button Groups', component: ButtonGroups },
  { path: '/buttons/brand-buttons', name: 'Brand Buttons', component: BrandButtons },
  { path: '/buttons/loading-buttons', name: 'Loading Buttons', component: LoadingButtons },
  { path: '/icons', exact: true, name: 'Icons', component: CoreUIIcons },
  { path: '/icons/coreui-icons', name: 'CoreUI Icons', component: CoreUIIcons },
  { path: '/icons/flags', name: 'Flags', component: Flags },
  { path: '/icons/font-awesome', name: 'Font Awesome', component: FontAwesome },
  { path: '/icons/simple-line-icons', name: 'Simple Line Icons', component: SimpleLineIcons },
];

export default routes;
